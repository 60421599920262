export default function PenIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_796_14788)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8707 0.96083C11.3812 0.749385 11.9548 0.749385 12.4652 0.96083C12.6944 1.05574 12.8811 1.19441 13.0503 1.34208C13.2103 1.48158 13.3905 1.66179 13.596 1.8673L14.134 2.40529C14.3395 2.61079 14.5197 2.79098 14.6592 2.95092C14.8069 3.12021 14.9455 3.30691 15.0404 3.53604C15.2519 4.04651 15.2519 4.62008 15.0404 5.13055C14.9455 5.35968 14.8069 5.54638 14.6592 5.71567C14.5197 5.87559 14.3395 6.05577 14.134 6.26126L7.86054 12.5347C6.22871 14.1665 4.01548 15.0833 1.70773 15.0833C1.27156 15.0833 0.917969 14.7297 0.917969 14.2935C0.917969 11.9858 1.83472 9.77255 3.46655 8.14072L9.73994 1.86733C9.94545 1.6618 10.1257 1.48159 10.2856 1.34208C10.4549 1.19441 10.6416 1.05574 10.8707 0.96083ZM11.8912 2.34665C11.7483 2.28744 11.5877 2.28744 11.4447 2.34665C11.4288 2.35323 11.3832 2.37513 11.2716 2.47248C11.1549 2.57427 11.011 2.7176 10.7841 2.9445L10.3953 3.33329L12.668 5.60597L13.0568 5.21718C13.2837 4.99027 13.427 4.84637 13.5288 4.72967C13.6261 4.61807 13.648 4.57242 13.6546 4.55653C13.7138 4.41359 13.7138 4.253 13.6546 4.11006C13.648 4.09417 13.6261 4.04852 13.5288 3.93691C13.427 3.82022 13.2837 3.67631 13.0568 3.44941L12.5519 2.9445C12.3249 2.7176 12.181 2.57427 12.0643 2.47248C11.9527 2.37513 11.9071 2.35323 11.8912 2.34665ZM11.6073 6.66663L9.33463 4.39395L4.52721 9.20138C3.35448 10.3741 2.62799 11.9097 2.45703 13.5442C4.09158 13.3733 5.62715 12.6468 6.79988 11.4741L11.6073 6.66663Z"
          fill="#808191"
        />
      </g>
      <defs>
        <clipPath id="clip0_796_14788">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
