export default function BlackYoutubeIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 26 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0829 3.24854H13.2275C14.5633 3.25341 21.3314 3.30216 23.1562 3.79291C23.7079 3.94268 24.2106 4.23462 24.6141 4.63955C25.0176 5.04448 25.3078 5.54821 25.4556 6.10041C25.6197 6.71791 25.7351 7.53529 25.8131 8.37866L25.8294 8.54766L25.8651 8.97016L25.8781 9.13916C25.9837 10.6244 25.9967 12.0154 25.9984 12.3193V12.4412C25.9967 12.7564 25.9821 14.2417 25.8651 15.7887L25.8521 15.9593L25.8375 16.1283C25.7562 17.0578 25.636 17.9808 25.4556 18.66C25.3082 19.2125 25.0182 19.7164 24.6147 20.1214C24.2111 20.5264 23.7081 20.8182 23.1562 20.9675C21.2712 21.4745 14.1066 21.5103 13.1137 21.5119H12.883C12.3809 21.5119 10.3041 21.5022 8.12663 21.4274L7.85037 21.4177L7.709 21.4112L7.43112 21.3998L7.15325 21.3884C5.3495 21.3088 3.63188 21.1804 2.8405 20.9659C2.2888 20.8167 1.78598 20.5252 1.38243 20.1205C0.978873 19.7158 0.688751 19.2122 0.541125 18.66C0.36075 17.9824 0.2405 17.0578 0.15925 16.1283L0.14625 15.9577L0.13325 15.7887C0.0530523 14.6876 0.00861228 13.5841 0 12.4802L0 12.2803C0.00325 11.9309 0.01625 10.7235 0.104 9.39104L0.115375 9.22366L0.12025 9.13916L0.13325 8.97016L0.169 8.54766L0.18525 8.37866C0.26325 7.53529 0.378625 6.71629 0.54275 6.10041C0.690127 5.54799 0.980139 5.04402 1.38371 4.63902C1.78728 4.23402 2.29023 3.94224 2.84212 3.79291C3.6335 3.58166 5.35112 3.45166 7.15487 3.37041L7.43112 3.35904L7.71062 3.34929L7.85037 3.34441L8.12825 3.33304C9.67478 3.28327 11.2219 3.25564 12.7692 3.25016H13.0829V3.24854ZM10.4 8.46479V16.294L17.1551 12.381L10.4 8.46479Z"
        fill="#0F0F0F"
      />
    </svg>
  );
}
