export default function UserIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.395813C6.03249 0.395813 4.4375 2.07941 4.4375 4.15623C4.4375 6.23305 6.03249 7.91665 8 7.91665C9.96751 7.91665 11.5625 6.23305 11.5625 4.15623C11.5625 2.07941 9.96751 0.395813 8 0.395813ZM5.9375 4.15623C5.9375 2.95386 6.86091 1.97915 8 1.97915C9.13909 1.97915 10.0625 2.95386 10.0625 4.15623C10.0625 5.3586 9.13909 6.33331 8 6.33331C6.86091 6.33331 5.9375 5.3586 5.9375 4.15623Z"
        fill="#808191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.49998C2.10124 9.49998 0.125 12.8863 0.125 14.6458C0.125 15.4195 0.372585 16.1257 0.860295 16.6405C1.348 17.1553 2.01706 17.4166 2.75 17.4166H13.25C13.9829 17.4166 14.652 17.1553 15.1397 16.6405C15.6274 16.1257 15.875 15.4195 15.875 14.6458C15.875 12.8863 13.8988 9.49998 8 9.49998ZM1.625 14.6458C1.625 14.0303 2.64876 11.0833 8 11.0833C13.3512 11.0833 14.375 14.0303 14.375 14.6458C14.375 15.0597 14.2476 15.343 14.079 15.5209C13.9105 15.6988 13.6421 15.8333 13.25 15.8333H2.75C2.35794 15.8333 2.0895 15.6988 1.92096 15.5209C1.75241 15.343 1.625 15.0597 1.625 14.6458Z"
        fill="#808191"
      />
    </svg>
  );
}
